import { createStore } from "vuex";

export default createStore({
  state: {
    faculty: [
      {
        name: "Dr. Paul Marotta, MD, FRCPC - Chair",
        position: [
          "Consultant Hepatologist",
          "Adjunct Professor University of Western Ontario",
          "London Health Sciences Centre",
        ],
      },
      {
        name: "Dr. Reena Khanna, MD, FRCPC - Chair",
        position: [
          "Associate Professor, University of Western Ontario",
          "Director, Medical and Imaging Research, Robarts Clinical Trials",
          "Gastroenterologist, London Health Sciences Centre",
        ],
      },
      {
        name: "Dr. Qasim Khan, MD, FRCPC",
        position: [
          "Assistant Prof of Medicine - Western University",
          "Transplant Hepatology and Gastroenterology",
        ],
      },
      {
        name: "Dr. Karim Qumosani, MD, FRCPC",
        position: [
          "Associate  Professor, Western universuty ",
          "Program Director : Gastroenterology and Hepatology",
          "University Hospital, London Health Sciences Centre",
        ],
      },
      {
        name: "Dr. Amindeep Sandhu, MD FRCPC",
        position: [
          "Assistant Professor",
          "Western University, LHSC/SJH",
        ],
      },
      {
        name: "Dr. Juan Pablo Arab",
        position: [
          "Associate Professor of Medicine, University of Western Ontario",
          "University Hospital, London Health Sciences Centre",
        ],
      },
      {
        name: "Dr. Michael Sey, MD, FRCPC",
        position: [
          "Associate Professor of Medicine, Western University",
          "Gastroenterologist, London Health Sciences Centre",
        ],
      },
      {
        name: "Dr Jamie Gregor, MD, FRCPC",
        position: [
          "Professor of Medicine, Western University",
          "Gastroenterologist, London Health Sciences",
        ],
      },
      {
        name: "Others: TBD…",
        position: [
          ""
        ],
      },
    ],
    agenda: [
      {
        title: "Session 1 GI:",
      },
      {
        time: "08:30am - 09:00am",
        desc: [
          "Rashes in GI",
          "A focus on IBD",
        ],
        speaker: "Dr. Fiona Lovegrove",
      },
      {
        time: "09:00am - 09:30am",
        desc: [
          "New Issues in Metabolic Liver Disease.",
          "Review the new nomenclature for Metabolic associated Steatotic Liver disease.",
          "How to stratify patients and who to screen for MASLD.",
          "Review the current therapies for MASLD in 2024."
        ],
        speaker: "Dr Qasim Khan",
      },
      {
        time: "09:30am - 10:00am",
        desc: [
          "Current and Future Therapies in IBD - What is available and what are we Waiting for?",
          "Touch on current biosimilar switch programs.",
          "Touch on next generation of therapy.",
          "Touch on strategy of what to start or wait or add-on."
        ],
        speaker: "Dr Reena Khanna",
      },
      {
        time: "10:00am - 10:30am ",
        desc: ["Break"],
        speaker: "",
      },
      {
        title: "Session 2 HEP:",
      },
      {
        time: "10:30am - 11:00am",
        desc: [
          "The fall of Hepatitis C and the rise in Liver Transplant Needs.",
          "Eradication of Hepatitis C - Methods of getting treatment to the 'Hard to Treat'.",
          "Evaluating Advanced Cirrhosis - Who to Transplant and When to Refer.",
          "How to Manage Portal Hypertensive Complications."
        ],
        speaker: "Dr. Karim Qumosani",
      },
      {
        time: "11:00am - 11:30am",
        desc: [
          "What's New in Endoscopy? ",
        ],
        speaker: "Dr. Brian Yan",
      },
      {
        time: "11:30am - 12:00pm",
        desc: [
          "Current Strategies to Treat Those with Primary Biliary Cholangitis and Advanced Alcohol Induced Liver Disease.",
          "Review of Current Options to Treat and Monitor PBC.",
          "To Review the Health Burden of Alcohol related liver Disease.",
          "Provide Guidance for Referral and Transplantation of Alcohol related Liver Disease."
        ],
        speaker: "Dr. JP Arab",
      },
      {
        time: "12:00pm - 1:00pm",
        desc: ["Lunch"],
        speaker: "Lunch with Sponsors",
      },
      {
        workshop: "Workshops 1:00 - 2:30pm (2 X 40 minute sessions)",
      },
      {
        workshopTitle: "LIVER WORKSHOP : Practical Demonstration of Technologies for Fibrosis Assessment",
      },
      {
        time: "",
        desc: [
          "Live Demonstration of Current and Future Fibrosis Assessment Tools : Shear Wave and Transient Elastography.",
          "Review a Clinical Pathway to Stream and Screen Patients with MASLD for South Western Ontario Health Care Providers.",
          "Review the Pros / Cons / Costs of Biochemical and Physical Diagnostic tests for MASLD."
        ],
        speaker: "Dr. Amindeep Sandhu and Dr Paul Marotta",
      },
      {
        workshopTitle: "GI WORKSHOP : The Bariatric Clinic"
      },
      {
        time: "",
        desc: [
          "Medical/new therapeutics and surgical update",
        ],
        speaker: "Dr. Ahmad Elnahas and Dr. Marko Mrkobrada",
      },
      {
        title: "Closing"
      },
      {
        time: "2.45pm",
        desc: ["Closing:"],
        speaker: "Dr. Khanna/Dr Marotta",
      },
      {
        time: "2.45pm",
        desc: ["Adjourn"],
        speaker: "",
      }
    ],
    videos: [
      "https://player.vimeo.com/video/642263700?h=5bb50fc9fb",
      "https://player.vimeo.com/video/642263700?h=5bb50fc9fb",
      "https://player.vimeo.com/video/642263700?h=5bb50fc9fb",
      "https://player.vimeo.com/video/642263700?h=5bb50fc9fb",
      "https://player.vimeo.com/video/642263700?h=5bb50fc9fb",
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
