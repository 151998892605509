<template>
  <footer
  v-if="show"
    @click="goToLink('https://stanleyparkcreative.com/')"
    :class="isBig ? 'footer1' : 'footer1 footer2'"
  >
    © 2022 Stanley Park Creative
  </footer>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    const isBig = ref(false);
    const show = ref(false);
    const goToLink = (e) => {
      window.open(e);
    };
    onMounted(() => {
      setTimeout(() => {
        if (window.innerHeight < document.body.clientHeight) {
          isBig.value = true;
          show.value = true
        } else {
          isBig.value = false;
          show.value = true
        }
      }, 100);
    });
    return { isBig, goToLink, show };
  },
};
</script>

<style lang="scss" scoped>
.footer1 {
  display: block;
  color: grey;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  &:hover {
    color: #85053b;
  }
}
.footer2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
