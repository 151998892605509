<template>
  <div class="nav">
    <div class="img-container">
      <img src="../assets/logo.png" alt="no photo" />
    </div>
    <div class="nav-items">
      <p @click="navigate('topics')">Objectives</p>
      <p @click="navigate('faculty')">Faculty</p>
      <p @click="navigate('agenda')">Agenda</p>
      <p @click="navigate('sponsors')">Sponsors</p>
      <p @click="register">Registration</p>
    </div>
    <div class="hamburger">
      <q-btn outline round color="primary" icon="view_headline">
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item clickable v-close-popup>
              <a href="#topics">Objectives</a>
            </q-item>
            <q-item clickable v-close-popup>
              <a href="#faculty">Faculty</a>
            </q-item>
            <q-item clickable v-close-popup>
              <a href="#agenda">Agenda</a>
            </q-item>
            <q-item clickable v-close-popup>
              <a href="#presentation">Presentation</a>
            </q-item>
            <q-item clickable v-close-popup>
              <a href="#sponsors">Sponsors</a>
            </q-item>
            <q-item clickable v-close-popup>
              <q-item-section @click="register">Reregistration</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
  </div>
  <div class="title">
    <h1><b>Date:</b> Saturday, Feb 3rd, 2024 from 8:30am to 3:00pm</h1>
    <h1><b>Location:</b> IVEY SPENCER Leadership Centre, London ON</h1>
  </div>
  <div class="pic-map">
    <img src="../assets/home.jpg" alt="no photo" />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2917.09541563357!2d-81.26254678452273!3d43.01837877914826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882eee724907b905%3A0xb5fe040aa2e72f35!2s551%20Windermere%20Rd%2C%20London%2C%20ON%20N5X%202T1!5e0!3m2!1sen!2sca!4v1636554710660!5m2!1sen!2sca"
      width="600"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
  <div class="title">
    <h1>Registration</h1>
  </div>
  <p class="sub" style="margin: 20px auto; text-align: center">
    Click here to go to the registration form
  </p>
  <div style="margin: 20px auto; text-align: center">
    <q-btn
      outline
      color="primary"
      label="Register Now"
      @click="register"
      class="reg-button"
    />
  </div>
  <div class="title" id="topics">
    <h1>PROGRAM LEARNING OBJECTIVES</h1>
  </div>
  <div class="topics">
    <p class="sub">
      After this educational event, participants will have engaged in current
      strategies and up-to-date approaches in the diagnosis and management of:
    </p>
    <p class="indent">
      &cir;<b> GI Learning Objective: </b>Understand and apply the latest
      advancements in gastrointestinal treatments and technology, including the
      clinical application of biosimilars in inflammatory bowel diseases and the
      integration of artificial intelligence within GI procedural practices, to
      enhance patient outcomes.
    </p>
    <p class="indent">
      &cir;<b> Liver Learning Objective: </b>Identify and manage complex
      hepatological conditions through improved non-invasive diagnostic
      measures, including the use of artificial intelligence in liver imaging,
      and the implementation of current best practices in the management of
      cirrhosis and hepatocellular carcinoma.
    </p>
  </div>
  <div class="title" id="agenda">
    <h1>Agenda</h1>
  </div>
  <div v-for="(a, i) in agenda" :key="i">
    <div v-if="a.title">
      <p class="agenda-title">{{ a.title }}</p>
    </div>
    <div v-else-if="a.workshop">
      <p class="agenda-workshop">{{ a.workshop }}</p>
    </div>
    <div v-else-if="a.workshopTitle">
      <p class="agenda-workshop-title">{{ a.workshopTitle }}</p>
    </div>
    <div
      v-else
      class="agenda"
      :class="{ grey: !a.speaker || a.speaker === 'Lunch with Sponsors' }"
    >
      <p>{{ a.time }}</p>
      <div v-if="a.desc.length">
        <p v-for="(desc, i) in a.desc" :key="i" style="text-align: left;">
          <b v-if="i === 0">{{ desc }}</b>
          <span v-else>{{ desc }}</span>
        </p>
      </div>
      <p>{{ a.speaker }}</p>
    </div>
    <hr style="background: grey" />
  </div>
  <div class="title" id="faculty">
    <h1>Faculty</h1>
  </div>
  <br />
  <div v-for="(f, i) in faculty" :key="i" class="faculty">
    <p class="name">{{ f.name }}</p>
    <p v-for="(p, i) in f.position" :key="i">{{ p }}</p>
    <br />
  </div>
  <!-- <div class="title" id="presentation">
    <h1>Presentation Decks and Videos</h1>
  </div> -->
  <!-- <div v-if="!reveal">
    <p class="sub">Please login to access the presentation decks</p>
    <div class="password">
      <q-input outlined v-model="password" label="Password" class="pass" />
      <q-btn outline color="primary" label="Submit" @click="submit" />
    </div>
  </div>
  <div v-else>
    <p class="sub">Click to view and download a presentation deck:</p>
    <div class="links">
      <p @click="goToLink('http://google.com')">
        &cir;&emsp; Dr. Karim Qumosani - 'Curative and Soon-to-be Curative
        Therapies in Hepatitis'
      </p>
      <p @click="goToLink('http://google.com')">
        &cir;&emsp; Dr. Supriya Joshi - 'Identification and Management of the
        High Risk Fatty Liver Disease Patient'
      </p>
      <p @click="goToLink('http://google.com')">
        &cir;&emsp; Dr. Reena Khanna - 'London Liver IBD Update'
      </p>
      <p @click="goToLink('http://google.com')">
        &cir;&emsp; Dr. Michael Sey - 'Optimal Strategies to Investigate Obscure
        GI Bleeding'
      </p>
    </div>
    <br />
    <p class="sub">Watch the presentations again:</p>
    <br />
    <div
      v-for="(v, i) in videos"
      :key="i"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <iframe
        :src="v"
        style="
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 95%;
          height: 100%;
          margin: auto;
        "
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div> -->
  <!-- <div>
    <p class="sub indent">Coming Soon....</p>
  </div> -->

  <div class="title" id="sponsors">
    <h1>Sponsors</h1>
  </div>
  <div>
    <div class="sponsors">
      <div class="spons-img">
        <img
          src="../assets/organon.png"
          alt="no photo"
          @click="goToLink('https://www.organon.com/')"
        />
        <img
          src="../assets/lupin.png"
          alt="no photo"
          @click="goToLink('http://www.lupinpharma.ca/#welcome')"
        />
        <img
          src="../assets/gilead.jpg"
          alt="no photo"
          @click="goToLink('https://www.gilead.com/')"
        />
        <img
          src="../assets/Biojamp.png"
          alt="no photo"
          @click="goToLink('https://biojamp.com/en')"
        />
        <img
          src="../assets/Advanz.jpg"
          alt="no photo"
          @click="goToLink('https://www.advanzpharma.ca/')"
        />
        <img
          src="../assets/pfizer.png"
          alt="no photo"
          @click="goToLink('https://www.pfizer.ca/')"
        />
        <img
          src="../assets/eisai.png"
          alt="no photo"
          @click="goToLink('https://www.eisai.com/index.html')"
        />
        <img
          src="../assets/fk.png"
          alt="no photo"
          @click="goToLink('https://www.fresenius-kabi.com/')"
        />
        <img
          src="../assets/takeda.png"
          alt="no photo"
          @click="goToLink('https://www.takeda.com/en-ca/')"
        />
        <img
          src="../assets/abbvie.png"
          alt="no photo"
          @click="goToLink('https://www.abbvie.ca/')"
        />
        <img
          src="../assets/sentrex.png"
          alt="no photo"
          @click="goToLink('https://sentrex.com/about/')"
        />
      </div>
    </div>
    <!-- <div class="sponsors">
      <h1>Gold</h1>
        <img
          src="../assets/bms.png"
          alt="no photo"
          @click="goToLink('https://www.bms.com/')"
        />
        <img
          src="../assets/jsn.png"
          alt="no photo"
          @click="goToLink('https://www.janssen.com/')"
        />
      <div class="spons-img-2">
      </div>
      <div class="spons-img-2">
      </div>
    </div>
    <div class="sponsors">
      <h1>Silver</h1>
      <div class="spons-img-2">
      </div>
    </div> -->
  </div>
  <Footer />
</template>

<script>
import { onMounted, ref } from "vue";
import store from "../store";
import { useRouter } from "vue-router";
import Footer from "../components/Footer.vue";
export default {
  components: { Footer },
  setup() {
    const router = useRouter();
    const faculty = store.state.faculty;
    const agenda = store.state.agenda;
    const password = ref("");
    const reveal = ref(false);
    const videos = store.state.videos;
    const submit = () => {
      if (password.value === "London2021") {
        reveal.value = true;
        window.localStorage.setItem("password", password.value);
        location.href = "#presentation";
      } else {
        window.alert("Please enter a valid Password...");
      }
    };
    const goToLink = (e) => {
      window.open(e);
    };
    const navigate = (e) => {
      window.location.href = `#${e}`;
    };
    const register = () => {
      router.push({ name: "Registration" });
    };
    onMounted(() => {
      window.scrollTo(0, 0);
      // var pass = window.localStorage.getItem("password");
      // if (pass === "London2021") {
      //   reveal.value = true;
      // }
    });
    return {
      faculty,
      agenda,
      password,
      submit,
      reveal,
      goToLink,
      videos,
      navigate,
      register,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
}
.img-container {
  text-align: left;
  flex: 2;
  img {
    width: 200px;
    margin-left: 30px;
  }
}
.nav-items {
  flex: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  p {
    font-size: 20px;
    cursor: pointer;
    &:hover {
      border-bottom: solid 2px #85053b;
    }
  }
}
.hamburger {
  padding-right: 20px;
  display: none;
}
a {
  text-decoration: none;
  color: rgb(71, 68, 68);
}
.title {
  background: #85053b;
  min-height: 100px;
  padding: 20px;
  h1 {
    color: white;
    font-family: "Open Sans", sans-serif;
    text-align: center;
  }
}
.sub {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}
.indent {
  margin-left: 10px;
}
.pic-map {
  display: flex;
  * {
    width: 50%;
    height: auto;
  }
}
.padding {
  padding: {
    left: 30px;
    right: 30px;
  }
  text-align: left;
}
.topics {
  @extend .padding;
}
.faculty {
  @extend .padding;
  p {
    margin: 5px 0;
  }
  .name {
    font-size: 20px;
    font-weight: bold;
  }
}
@mixin temp($leftMargin) {
  margin: 10px 5px 10px $leftMargin;
  text-align: left;
  @media (max-width: 600px) {
    margin: 10px;
  }
}
@mixin flex($size1, $size2) {
  flex: $size1;
  @media (max-width: 600px) {
    flex: $size2;
  }
}
.agenda {
  display: flex;
  :nth-child(1) {
    @include flex(2, 3);
    @include temp(0);
  }
  :nth-child(2) {
    @include flex(7, 5);
    text-align: left;
    @include temp(10px);
  }
  :nth-child(n + 3) {
    @include flex(3, 4);
    text-align: left;
    @include temp(10px);
  }
}
.agenda-title {
  font-size: 26px;
  font-weight: bold;
  color: #85053b;
  margin-left: 10px;
  margin-bottom: 0;
}
.agenda-workshop-title {
  @extend .agenda-title;
  font-size: 20px;
  color: gray;
}
.agenda-workshop {
  font-size: 30px;
  font-weight: bold;
  color: #85053b;
  margin-top: 20px;
  text-align: center;
}
.grey {
  color: rgb(175, 173, 173);
  font-weight: bold;
}
.password {
  display: flex;
  justify-content: left;
  margin: 10px {
    left: 20px;
  }
  * {
    margin: {
      left: 5px;
      right: 5px;
    }
  }
}
.pass {
  min-width: 150px;
  max-width: 300px;
}
.links {
  margin-left: 30px;
  p {
    cursor: pointer;
    &:hover {
      color: #85053b;
      font-weight: bold;
    }
  }
}
.vid {
  width: 100% !important;
}
.reg-button {
  padding: 20px;
}
.sponsors {
  text-align: center;
  min-height: 300px;
  margin: 10px;
  border: #85053b solid 5px {
    radius: 20px;
  }
  h1 {
    color: #85053b;
    background: #85053a21;
    margin-top: 0;
    padding: 10px;
  }
}
.spons-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: {
    bottom: 20px;
  }
  img {
    width: 400px;
    margin: {
      left: 40px;
      right: 40px;
    }
    cursor: pointer;
  }
}
.spons-img-2 {
  @extend .spons-img;
  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
  }
}
@media (max-width: 1650px) {
  .spons-img img {
    width: 250px;
  }
}
@media (max-width: 1050px) {
  .spons-img img {
    width: 200px;
  }
  .sponsors {
    min-height: auto;
  }
}
@media (max-width: 850px) {
  .spons-img img,
  .spons-img-2 img {
    width: 170px;
  }
  .agenda-title {
    font-size: 22px;
  }
  .agenda-workshop {
    font-size: 24px;
  }
}
@media (max-width: 750px) {
  .pic-map {
    flex-direction: column;
    * {
      width: 100%;
      min-height: 500px;
      @media (max-width: 500px) {
        min-height: 300px;
      }
    }
  }
  .img-container img {
    width: 75px;
    margin-left: 15px;
  }
  .nav-items {
    display: none;
  }
  .hamburger {
    display: initial;
  }
  // .spons-img-2 {
  //   display: block;
  // }
}
@media (max-width: 650px) {
  .agenda-title {
    font-size: 20px;
  }
  .agenda-workshop {
    font-size: 22px;
  }
}
@media (max-width: 555px) {
  .spons-img img {
    width: 150px;
  }
}
@media (max-width: 500px) {
  .spons-img img,
  .spons-img-2 img {
    width: 100px;
    margin: {
      left: 20px;
      right: 20px;
    }
  }
  .agenda-title {
    font-size: 18px;
  }
  .agenda-workshop {
    font-size: 20px;
  }
  .agenda {
    p {
      font-size: 14px;
    }
  }
}
@media (max-width: 340px) {
  .spons-img img {
    width: 80px;
  }
}
</style>
